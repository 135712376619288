import { type ReactNode, createContext, useState } from 'react'
import type { BatteryState } from 'src/network/battery/types'

export type BatteryContextType = {
    serialFilter?: string[]
    zoneFilter?: string
    stateFilter?: BatteryState
    warehouseFilter?: string
    previousRoute?: string
    setSerialFilter: (serialFilter?: string[]) => void
    setZoneFilter: (zoneFilter?: string) => void
    setStateFilter: (stateFilter?: BatteryState) => void
    setWarehouseFilter: (warehouseFilter?: string) => void
    setPreviousRoute: (previousRoute?: string) => void
}

export const BatteryContext = createContext<BatteryContextType | null>(null)

const BatteryContextProvider = ({ children }: { children: ReactNode }) => {
    const [serialFilter, setSerialFilter] = useState<string[]>()
    const [zoneFilter, setZoneFilter] = useState<string>()
    const [stateFilter, setStateFilter] = useState<BatteryState>()
    const [warehouseFilter, setWarehouseFilter] = useState<string>()
    const [previousRoute, setPreviousRoute] = useState<string>()

    return (
        <BatteryContext.Provider
            value={{
                serialFilter,
                zoneFilter,
                stateFilter,
                warehouseFilter,
                previousRoute,
                setSerialFilter,
                setZoneFilter,
                setStateFilter,
                setWarehouseFilter,
                setPreviousRoute,
            }}
        >
            {children}
        </BatteryContext.Provider>
    )
}

export default BatteryContextProvider
