import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import {
    toggleMobileNav,
    expandZone,
    expandRepairs,
    expandVehicles,
    expandBattery,
    selectIsMobileNavOpen,
    selectIsZoneExpanded,
    selectIsRepairsExpanded,
    selectIsVehiclesExpanded,
    selectIsRidersExpanded,
    selectIsBatteryExpanded,
    expandRiders,
} from 'src/redux/nav'
import * as colors from 'src/ui-kit/colors/colors'
import RidersIcon from 'src/assets/images/nav/riders-icon.svg'
import RideInspectorIcon from 'src/assets/images/nav/ic_search.svg'
import HuntersIcon from 'src/assets/images/nav/hunters-icon.svg'
import VehiclesIcon from 'src/assets/images/nav/vehicles-icon.svg'
import CampaignsIcon from 'src/assets/images/nav/campaigns-icon.svg'
import ZonesIcon from 'src/assets/images/nav/zones-icon.svg'
import TuneIcon from 'src/assets/images/nav/tune-icon.svg'
import IconMarket from 'src/assets/images/city_regs.svg'
import MaasIcon from 'src/assets/images/nav/ic_api.svg'
import VmapIcon from 'src/assets/images/nav/vmap-icon.svg'
import HomeIcon from 'src/assets/images/nav/home-icon.svg'
import PermissionsIcon from 'src/assets/images/nav/permissions-icon.svg'
import RepairIcon from 'src/assets/images/nav/ic_repairs.svg'
import BatteryIcon from 'src/assets/images/nav/battery.svg'
import AccessControl from 'src/components/app/AccessControl'
import DropDown from 'src/assets/images/nav/dropdown-icon.svg'
import { ButtonText } from 'src/ui-kit/text/Text'
import * as routes from 'src/constants/routes'
import { zIndex } from 'src/constants/zIndex'
import { NavChildItem } from 'src/components/parts/navigation/DesktopNav'
import { permissions } from 'src/components/app/accessControl.permissions'
import { getPath } from 'src/components/app/utils/nav/navUtils'
import SupportSection from 'src/components/parts/navigation/SupportSection'
import { isAppEnv } from 'src/utils/env'

interface Props {
    activeLink: string
}

export function MobileNav({ activeLink }: Props) {
    const isMobileNavOpen = useAppSelector(selectIsMobileNavOpen)
    const isZoneExpanded = useAppSelector(selectIsZoneExpanded)
    const isRepairsExpanded = useAppSelector(selectIsRepairsExpanded)
    const isRidersExpanded = useAppSelector(selectIsRidersExpanded)
    const isVehiclesExpanded = useAppSelector(selectIsVehiclesExpanded)
    const isBatteryExpanded = useAppSelector(selectIsBatteryExpanded)

    const dispatch = useAppDispatch()

    const currentPath = getPath(activeLink)

    if (!isMobileNavOpen) {
        return null
    }

    return (
        <MainWrapper>
            <Content>
                <ButtonText $color={colors.coral} style={{ marginLeft: '20px' }}>
                    MENU
                </ButtonText>
                <Link to={routes.OVERVIEW} onClick={() => dispatch(toggleMobileNav(false))}>
                    <NavItem $active={currentPath === routes.OVERVIEW}>
                        <NavIcon src={HomeIcon} alt='' />
                        <ButtonText $verticalAlign $color={colors.white}>
                            HOME
                        </ButtonText>
                    </NavItem>
                </Link>
                <AccessControl allow={permissions.hunter_editor}>
                    <Link
                        to={routes.HUNTERS}
                        data-testid='hunters-navigation-link'
                        onClick={() => dispatch(toggleMobileNav(false))}
                    >
                        <NavItem $active={currentPath === routes.HUNTERS || currentPath === routes.ORG}>
                            <NavIcon src={HuntersIcon} alt='' />
                            <ButtonText $verticalAlign $color={colors.white}>
                                HUNTERS
                            </ButtonText>
                        </NavItem>
                    </Link>
                </AccessControl>
                <AccessControl allow={permissions.users_viewer}>
                    <NavItem $noPadding $active={currentPath === routes.RIDERS}>
                        <Link
                            style={{ flex: '1', padding: '10px 10px' }}
                            to={routes.RIDERS}
                            data-testid='riders-navigation-link'
                            onClick={() => dispatch(toggleMobileNav(false))}
                        >
                            <Row>
                                <NavIcon src={RidersIcon} alt='' />
                                <ButtonText $verticalAlign $color={colors.white}>
                                    RIDERS
                                </ButtonText>
                            </Row>
                        </Link>
                        <AccessControl allow={permissions.accident_editor}>
                            <img
                                src={DropDown}
                                data-testid='expanded-riders-navbar'
                                alt=''
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: 'auto',
                                    transform: isRidersExpanded ? '' : 'rotate(180deg)',
                                }}
                                onClick={() => dispatch(expandRiders(!isRidersExpanded))}
                            />
                        </AccessControl>
                    </NavItem>
                    {isRidersExpanded && (
                        <div>
                            <AccessControl allow={permissions.accident_editor}>
                                <NavItem $noPadding $active={currentPath === routes.ACCIDENT_REPORTS}>
                                    <Link
                                        data-testid='accident-reports-navigation-link'
                                        style={{
                                            flex: '1',
                                            display: 'flex',
                                            justifyContent: 'left',
                                            paddingLeft: '35px',
                                        }}
                                        to={routes.ACCIDENT_REPORTS}
                                        onClick={() => dispatch(toggleMobileNav(false))}
                                    >
                                        <NavChildItem>
                                            <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                                - ACCIDENT REPORTS
                                            </ButtonText>
                                        </NavChildItem>
                                    </Link>
                                </NavItem>
                            </AccessControl>
                            <NavItem $noPadding $active={currentPath === routes.RIDE_INSPECTOR}>
                                <Link
                                    style={{
                                        flex: '1',
                                        display: 'flex',
                                        justifyContent: 'left',
                                        paddingLeft: '35px',
                                    }}
                                    to={routes.RIDE_INSPECTOR}
                                    onClick={() => dispatch(toggleMobileNav(false))}
                                >
                                    <NavChildItem>
                                        <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                            - RIDE INSPECTOR
                                        </ButtonText>
                                    </NavChildItem>
                                </Link>
                            </NavItem>
                        </div>
                    )}
                </AccessControl>
                <Link
                    to={routes.RIDE_INSPECTOR}
                    data-testid='ride-inspector-navigation-link'
                    onClick={() => dispatch(toggleMobileNav(false))}
                >
                    <NavItem $active={currentPath === routes.RIDE_INSPECTOR}>
                        <NavIcon src={RideInspectorIcon} alt='Ride Inspector' />
                        <ButtonText $verticalAlign $color={colors.white}>
                            RIDE INSPECTOR
                        </ButtonText>
                    </NavItem>
                </Link>
                <NavItem $noPadding $active={currentPath === routes.VEHICLES}>
                    <Link
                        style={{ flex: '1', padding: '10px 10px' }}
                        to={routes.VEHICLES}
                        data-testid='vehicles-navigation-link'
                        onClick={() => dispatch(toggleMobileNav(false))}
                    >
                        <Row>
                            <NavIcon src={VehiclesIcon} alt='' />
                            <ButtonText $verticalAlign $color={colors.white}>
                                VEHICLES
                            </ButtonText>
                        </Row>
                    </Link>
                    <AccessControl allow={permissions.parking_assistant_editor}>
                        <img
                            src={DropDown}
                            data-testid='expanded-scooters-navbar'
                            alt=''
                            style={{
                                cursor: 'pointer',
                                marginLeft: 'auto',
                                transform: isVehiclesExpanded ? '' : 'rotate(180deg)',
                            }}
                            onClick={() => dispatch(expandVehicles(!isVehiclesExpanded))}
                        />
                    </AccessControl>
                </NavItem>
                {isVehiclesExpanded && (
                    <>
                        <NavItem $noPadding $active={currentPath === routes.PARKING_PHOTO_LABELLING}>
                            <Link
                                style={{ flex: '1', display: 'flex', justifyContent: 'left', paddingLeft: '35px' }}
                                to={routes.PARKING_PHOTO_LABELLING}
                                onClick={() => dispatch(toggleMobileNav(false))}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - PARKING PHOTO LABELLING
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                        <NavItem $noPadding $active={currentPath === routes.SAFETY_CONTROL_SYSTEM}>
                            <Link
                                style={{ flex: '1', display: 'flex', justifyContent: 'left', paddingLeft: '35px' }}
                                to={routes.SAFETY_CONTROL_SYSTEM}
                                onClick={() => dispatch(toggleMobileNav(false))}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - SAFETY CONTROL SYSTEM
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                    </>
                )}
                <Link
                    to={routes.VMAP}
                    data-testid='vmap-navigation-link'
                    onClick={() => dispatch(toggleMobileNav(false))}
                >
                    <NavItem $active={currentPath === routes.VMAP}>
                        <NavIcon src={VmapIcon} alt='' />
                        <ButtonText $verticalAlign $color={colors.white}>
                            VEHICLE MAP
                        </ButtonText>
                    </NavItem>
                </Link>
                {isAppEnv('stage') && (
                    <>
                        <NavItem $noPadding $active={currentPath === routes.BATTERY_SEARCH}>
                            <Link
                                style={{ flex: '1', padding: '10px 10px' }}
                                to={routes.BATTERY_SEARCH}
                                data-testid='batteries-search-navigation-link'
                                onClick={() => dispatch(toggleMobileNav(false))}
                            >
                                <Row>
                                    <NavIcon src={BatteryIcon} alt='' />
                                    <ButtonText $verticalAlign $color={colors.white}>
                                        BATTERIES
                                    </ButtonText>
                                </Row>
                            </Link>
                            <img
                                src={DropDown}
                                data-testid='expanded-battery-navbar'
                                alt=''
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: 'auto',
                                    transform: isBatteryExpanded ? '' : 'rotate(180deg)',
                                }}
                                onClick={() => dispatch(expandBattery(!isBatteryExpanded))}
                            />
                        </NavItem>
                        {isBatteryExpanded && (
                            <NavItem $noPadding $active={currentPath === routes.BATTERIES}>
                                <Link
                                    data-testid='battery-navigation-link'
                                    style={{
                                        flex: '1',
                                        display: 'flex',
                                        justifyContent: 'left',
                                        paddingLeft: '15px',
                                    }}
                                    to={routes.BATTERIES}
                                    onClick={() => dispatch(toggleMobileNav(false))}
                                >
                                    <NavChildItem>
                                        <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                            - LIST / FILTER
                                        </ButtonText>
                                    </NavChildItem>
                                </Link>
                            </NavItem>
                        )}
                    </>
                )}
                <NavItem $noPadding $active={currentPath === routes.ZONES}>
                    <Link
                        style={{ flex: '1', padding: '10px 10px' }}
                        to={routes.ZONES}
                        data-testid='countries-navigation-link'
                        onClick={() => dispatch(toggleMobileNav(false))}
                    >
                        <Row>
                            <NavIcon src={ZonesIcon} alt='' />
                            <ButtonText $verticalAlign $color={colors.white}>
                                ZONES
                            </ButtonText>
                        </Row>
                    </Link>
                    <img
                        src={DropDown}
                        data-testid='expanded-zones-navbar'
                        alt=''
                        style={{
                            cursor: 'pointer',
                            marginLeft: 'auto',
                            transform: isZoneExpanded ? '' : 'rotate(180deg)',
                        }}
                        onClick={() => dispatch(expandZone(!isZoneExpanded))}
                    />
                </NavItem>
                {isZoneExpanded && (
                    <>
                        <NavItem $noPadding $active={currentPath === routes.LEGAL}>
                            <Link
                                style={{ flex: '1', display: 'flex', justifyContent: 'left', paddingLeft: '35px' }}
                                to='/legal'
                                onClick={() => dispatch(toggleMobileNav(false))}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - LEGAL ENTITIES
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                        <NavItem $noPadding $active={currentPath === routes.PRICING_PORTAL}>
                            <Link
                                style={{ flex: '1', display: 'flex', justifyContent: 'left', paddingLeft: '35px' }}
                                to={routes.PRICING_PORTAL}
                                onClick={() => dispatch(toggleMobileNav(false))}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - PRICING
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                        <NavItem $noPadding $active={currentPath === routes.WORK_AREAS}>
                            <Link
                                style={{ flex: '1', display: 'flex', justifyContent: 'left', paddingLeft: '35px' }}
                                to={routes.WORK_AREAS}
                                onClick={() => dispatch(toggleMobileNav(false))}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - WORK AREAS
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                    </>
                )}
                <Link
                    to={routes.CONFIGHUB}
                    data-testid='confighub-navigation-link'
                    onClick={() => dispatch(toggleMobileNav(false))}
                >
                    <NavItem $active={currentPath === routes.CONFIGHUB}>
                        <NavIcon src={TuneIcon} alt='' />
                        <ButtonText $verticalAlign $color={colors.white}>
                            CONFIG HUB
                        </ButtonText>
                    </NavItem>
                </Link>
                <Link
                    to={routes.CITYREGS}
                    data-testid='cityregs-navigation-link'
                    onClick={() => dispatch(toggleMobileNav(false))}
                >
                    <NavItem $active={currentPath === routes.CITYREGS}>
                        <NavIcon src={IconMarket} alt='' />
                        <ButtonText $verticalAlign $color={colors.white}>
                            CITY REGS
                        </ButtonText>
                    </NavItem>
                </Link>
                <AccessControl allow={permissions.partners_editor}>
                    <Link
                        to={routes.MAAS}
                        data-testid='light-integrations-navigation-link'
                        onClick={() => dispatch(toggleMobileNav(false))}
                    >
                        <NavItem $active={currentPath === routes.MAAS}>
                            <NavIcon src={MaasIcon} alt='' />
                            <ButtonText $verticalAlign $color={colors.white}>
                                LIGHT INTEGRATIONS
                            </ButtonText>
                        </NavItem>
                    </Link>
                </AccessControl>
                <AccessControl allow={permissions.partners_editor}>
                    <Link
                        to={routes.DEEP_INTEGRATIONS}
                        data-testid='deep-integrations-navigation-link'
                        onClick={() => dispatch(toggleMobileNav(false))}
                    >
                        <NavItem $active={currentPath === routes.DEEP_INTEGRATIONS}>
                            <NavIcon src={MaasIcon} alt='' />
                            <ButtonText $verticalAlign $color={colors.white}>
                                DEEP INTEGRATIONS
                            </ButtonText>
                        </NavItem>
                    </Link>
                </AccessControl>
                <Link
                    to={routes.CAMPAIGNS}
                    data-testid='campaigns-navigation-link'
                    onClick={() => dispatch(toggleMobileNav(false))}
                >
                    <NavItem $active={currentPath === routes.CAMPAIGNS}>
                        <NavIcon src={CampaignsIcon} alt='' />
                        <ButtonText $verticalAlign $color={colors.white}>
                            CAMPAIGNS
                        </ButtonText>
                    </NavItem>
                </Link>
                <NavItem $noPadding $active={currentPath === routes.REPAIR_MANAGEMENT}>
                    <Link
                        style={{ flex: '1', padding: '10px 10px' }}
                        to={routes.REPAIR_MANAGEMENT}
                        data-testid='rms-navigation-link'
                        onClick={() => dispatch(toggleMobileNav(false))}
                    >
                        <Row>
                            <NavIcon src={RepairIcon} alt='' />
                            <ButtonText $verticalAlign $color={colors.white}>
                                REPAIRS
                            </ButtonText>
                        </Row>
                    </Link>
                    <img
                        src={DropDown}
                        data-testid={isRepairsExpanded ? 'collapse-rms-navbar-btn' : 'expand-rms-navbar-btn'}
                        alt=''
                        style={{
                            cursor: 'pointer',
                            marginLeft: 'auto',
                            transform: isRepairsExpanded ? '' : 'rotate(180deg)',
                        }}
                        onClick={() => dispatch(expandRepairs(!isRepairsExpanded))}
                    />
                </NavItem>
                {isRepairsExpanded && (
                    <>
                        <NavItem $noPadding $active={currentPath === routes.ADMINISTRATION}>
                            <Link
                                style={{
                                    flex: '1',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    paddingLeft: '35px',
                                }}
                                to='/administration'
                                onClick={() => dispatch(toggleMobileNav(false))}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - ADMINISTRATION
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                        <NavItem $noPadding $active={currentPath === routes.WAREHOUSES}>
                            <Link
                                style={{
                                    flex: '1',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    paddingLeft: '35px',
                                }}
                                to={routes.WAREHOUSES}
                                onClick={() => dispatch(toggleMobileNav(false))}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - WAREHOUSES
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                        <NavItem $noPadding $active={currentPath === routes.MAINTENANCE_NEEDS}>
                            <Link
                                style={{
                                    flex: '1',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    paddingLeft: '35px',
                                }}
                                to={routes.MAINTENANCE_NEEDS}
                                onClick={() => dispatch(toggleMobileNav(false))}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - MAINTENANCE NEEDS
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                    </>
                )}
                <Link to={routes.PERMISSIONS} onClick={() => dispatch(toggleMobileNav(false))}>
                    <NavItem $active={currentPath === routes.PERMISSIONS}>
                        <NavIcon src={PermissionsIcon} alt='' />
                        <ButtonText $verticalAligns $color={colors.white}>
                            PERMISSIONS
                        </ButtonText>
                    </NavItem>
                </Link>
                <SupportSection expanded={true} />
            </Content>
        </MainWrapper>
    )
}

const MainWrapper = styled.div`
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    top: 70px;
    left: 0;
    background-color: ${colors.black};
    height: calc(100vh - 70px);
    z-index: ${zIndex.priority};
    justify-content: flex-start;
    padding-top: 30px;
    overflow: auto;
`

const NavItem = styled.div<{ $noPadding?: true; $active: boolean }>`
    display: flex;
    flex-direction: row;
    margin: 20px 10px;
    padding: ${props => (props.$noPadding ? '0 10px 0 0;' : '10px 10px;')};
    cursor: pointer;
    border-radius: 12px;
    align-items: center;
    opacity: ${props => (props.$active ? '1' : '0.5')};
    color: ${colors.white};

    &:hover {
        opacity: 1;
    }

    background-color: ${props => (props.$active ? colors.grey50 : '')};
    transition: all 0.2s ease-in-out;
`

const NavIcon = styled.img`
    margin-right: 20px;
    width: 20px;
    height: 20px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const Content = styled.div`
    max-width: 300px;

    /* Extra padding for accessibility on mobile devices */
    padding-bottom: 50px;
`
